<template>
  <div>
    <div style="padding: 30px 20px;">
      <ele-form
        v-model="RowInfo"
        :form-desc="formDesc"
        ref="form"
        :rules="rules"
        labelPosition="right"
        :isShowSubmitBtn="true"
        :isShowCancelBtn="false"
        :isShowBackBtn="false"
        :span="24"
        :request-fn="handleSubmit"
        :isShowErrorNotify="false"
      >
      </ele-form>
    </div>
  </div>
</template>

<script>
import { getComplaintAdd } from '@/api/qr_code';
export default {
  data() {
    return {
      RowInfo: {},
      formDesc: {
        site_name: {
          type: 'text',
          label: '',
          labelWidth: '0',
          style: {
            fontSize: '16px',
            fontWeight: 'bold'
            // textAlign:
          }
        },
        content: {
          type: 'textarea',
          label: '投诉内容'
        },
        complainant: {
          type: 'input',
          label: '投诉人'
        },
        tel: {
          type: 'input',
          label: '联系电话'
        }
      },
      rules: {
        content: [{ required: true, message: '请填写投诉内容' }]
      }
    };
  },
  created() {
    console.log(this.$route.query);
    this.RowInfo = {
      ...this.$route.query
    };
    // this.$route.query=
  },
  methods: {
    async handleSubmit(fields) {
      let params = {
        ...fields
      };
      console.log(params);
      let result;
      result = await getComplaintAdd(params);
      if (result && result.code === 0) {
        this.$message.success(result.msg);
        setTimeout(() => {
          if (WeixinJSBridge) WeixinJSBridge.call('closeWindow');
        }, 2000);
        return result;
      } else {
        this.$message.error(result.msg);
      }
    }
  }
};
</script>
<style></style>
